/* eslint-disable camelcase */

import {
  Box, DialogActions, Button, Stack, DialogContent,
} from '@mui/material';
import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';

import { useModalStore } from 'common/hooks/useModals';
import { serializeFormData } from 'common/util/serializeFormData';
import { useAddAircraftToAccount } from 'aircraft/hooks/useAddAircraftToAccount';
import { MdOutlineClose } from 'react-icons/md';
import AddAircraft from 'aircraft/components/AddAircraftModal/AddAircraft';
import { ADD_TO_ACCOUNT_STATUS, CanAddToAccountProvider, useCanAddToAccount } from 'aircraft/providers/CanAddToAccount';
import { useAircraftCountriesList } from 'aircraft/hooks/useGetAircraftCountries';
import { toast } from 'react-toastify';
import { useState } from 'react';

const CreateBilledAccountHeader = styled.div`
    font-weight: bold;
    font-size: 24px;
    padding: 12px;
  `;

const ModalDivider = styled(Divider)`
    backgroundColor: '#000';
`;

const ActionButton = styled(Button)`
    font-size: 10px;
`;

const ModalHeaderActionContainer = styled(Stack)`
  justify-content: space-between;
`;

const CloseButton = styled(Button)`
  font-size: 20px;
  color: black;
`;

const SubmitButton = styled(ActionButton)`
  width: 120px;
`;

const WarningNotice = styled.div`
  color: red;
  font-size: 10px;
  padding-top: 20px;
  text-align: right;
  width: 100%;
`;

function View({
  onSubmit,
  handleClose,
  showAlreadyRegistered,
  showExistsDeactive,
  disableSubmit,
  handleValidationChange,
}) {
  return (
    <form onSubmit={onSubmit}>
      <ModalHeaderActionContainer spacing={2} direction="row">
        <CreateBilledAccountHeader>
          Add Aircraft
        </CreateBilledAccountHeader>
        <CloseButton variant="text" onClick={handleClose}>
          <MdOutlineClose />
        </CloseButton>
      </ModalHeaderActionContainer>
      <ModalDivider component="div" />
      <DialogContent>
        <AddAircraft onValidationChange={handleValidationChange} />
        {showAlreadyRegistered && (
          <WarningNotice>
            This Aircraft is already registered.
          </WarningNotice>
        )}
        {showExistsDeactive && (
          <WarningNotice>
            This Aircraft exists deactive on another account.
            If you want to activate it on this account please use the `Aircraft Transfer` function on the
            {' '}
            <a href="/home/DeactivatedAircraft">Deactivated Aircraft</a>
            {' '}
            list.
          </WarningNotice>
        )}
      </DialogContent>
      <ModalDivider component="div" />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Box />
        <Stack spacing={2} direction="row">
          <SubmitButton disabled={disableSubmit} variant="contained" color="primary" type="submit">
            Add Aircraft
          </SubmitButton>
        </Stack>
      </DialogActions>
    </form>
  );
}

function Model() {
  const { canAddToAccount } = useCanAddToAccount();
  const { aircraftCountries } = useAircraftCountriesList();
  const { mutation: addAircraftToAccountMutation } = useAddAircraftToAccount();
  const [errorValidation, hasErrorValidation] = useState('');

  const {
    addAircraft,
    setAddAircraftState,
  } = useModalStore();

  const {
    company_id,
    account_name,
  } = addAircraft;

  const onSubmit = async (e) => {
    e.preventDefault();

    const {
      country_code, reg_n_number, serial_number, make, model,
    } = serializeFormData(e);
    const checkCountryExists = aircraftCountries.some(((code) => reg_n_number.startsWith(code.prefix)));
    if (checkCountryExists) {
      // checking whether the new_reg_number consists of country code
      toast.error('Please remove country code from the tail Registration Number and try again.');
    } else {
      addAircraftToAccountMutation({
        country_code,
        reg_n_number,
        serial_number,
        make,
        model,
        company_id,
        account_name,
      });
    }
  };

  const handleClose = () => {
    setAddAircraftState(false);
  };

  const handleValidationChange = (hasError) => {
    hasErrorValidation(hasError);
  };

  const hookProps = {
    onSubmit,
    handleClose,
    showAlreadyRegistered: canAddToAccount === ADD_TO_ACCOUNT_STATUS.ALREADY_REGISTERED,
    showExistsDeactive: canAddToAccount === ADD_TO_ACCOUNT_STATUS.EXISTS_DEACTIVE,
    disableSubmit: canAddToAccount === ADD_TO_ACCOUNT_STATUS.INCOMPLETE || canAddToAccount === ADD_TO_ACCOUNT_STATUS.ALREADY_REGISTERED || errorValidation !== '',
    handleValidationChange,
  };

  return (
    <View
      {...hookProps}
    />
  );
}

function ProvidedModel() {
  return (
    <CanAddToAccountProvider>
      <Model />
    </CanAddToAccountProvider>
  );
}

export default ProvidedModel;
export { View };
